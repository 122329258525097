/**
* メイン処理
*
*/

/* 要素の定義 */
var $RecruitLogoElement = $('#white_logo, #white_logo_header, #nav_under_logo, #under_logo');
var $Nav = $('#top_nav');
var $NormalHeaderLogo = $('#top_nav > h1 img');
var $WhiteHeaderLogo = $('#white_logo_header img');

/* 初期値の定義 */
var navTop = $Nav.offset().top;

/* ヘッダーサイズの初期処理 */
var topNavHeight = 0;
topNavHeight = $Nav.outerHeight();

/* AOS初期化 */
AOS.init({
	delay: 100,
	duration: 900,
	once: true,
	easing: 'ease-out-back'
});

/**
* ヘッダーのセット
* @param {String} paddingTop	上パディング
*/
function SetNormalHeader(paddingTop) {
	if( $(window).width() >= 576 || $('#top').length == 0 ){
		$('header').css('padding-top', paddingTop);
	}
}

/**
* ロゴの表示
* @param
*/
function DisplayWhiteLogo() {
	$NormalHeaderLogo.attr('src','./assets/img/logo_kclc_white.svg')
	$WhiteHeaderLogo.attr('src','./images/white_logo.svg');
}

/**
* カラーロゴの表示
* @param
*/
function DisplayColorLogo() {
	$NormalHeaderLogo.attr('src','./assets/img/logo_kclc.svg');
	$WhiteHeaderLogo.attr('src','./images/colored_logo.svg');
}

/**
* トグルクローズ時の処理
* @param {jQuery.Event} event 発火したイベントオブジェクト
*/
function OnCustomCloseToggle(event) {
	//bodyをメニュー以外の部分が見えるようにする
	$('body').css('overflow','visible');

	//アニメーションに合わせてスタイルをフェードアウト
	$('#top_nav .collapse.navbar-collapse').fadeOut(350,function(){
		//メニューボタン初期化
		$('.menu-btn').removeClass('active');
		//リクルートロゴ初期化
		$RecruitLogoElement.removeClass('active');
		//トグル部の初期化
		$('#top_nav .navbar-collapse').css({
			'max-height': '',
			'display': ''
		});
		//ヘッダーの初期化
		SetNormalHeader('');
		//ナビゲーション部の初期化
		$Nav.css('position', '').removeClass('bg-grad');

		//スクロール時の初期化処理
		if($Nav.hasClass('scroll')){
			event.preventDefault();
		}
		DisplayColorLogo();
	});
}


/**
* ターゲットへのスクロール処理
* 
*/
$('a[href^="#"]').click(function(e) {
	//トグルオープン時は閉じる
	if($('#top_nav .collapse.navbar-collapse').hasClass('show')){
		OnCustomCloseToggle(e);
		$('#top_nav .collapse.navbar-collapse').collapse("hide");
	}

	//設定
	var speed = 750;
	var href = $(this).attr("href");

	//ターゲット
	var target = $(href == "#" || href == "" ? 'html' : href);
	var position = target.offset().top - topNavHeight;

	//スクロール処理
	$('body,html').animate({
		scrollTop: position
	}, speed, 'swing');
});


/**
* ウインドウリサイズ時の処理
* 
*/
$(window).on('load resize', function(){
	//トグルクローズ時は高さを更新
	if(!($('.navbar-collapse.collapse').hasClass('show'))){
		topNavHeight = $Nav.outerHeight();
	}
});

/**
* ハンバーガーメニュー：クリック時の処理
* 
*/
$('.navbar-toggler').click(function(e){
	//クローズ処理
	if($('#top_nav .collapse.navbar-collapse').hasClass('show')){
		$('#top_nav .navbar-collapse ul').css('height','');
		OnCustomCloseToggle(e);
	//オープン処理
	}else{
		SetNormalHeader(topNavHeight + 'px');
		$('body').css('overflow', 'hidden');
		$('.menu-btn').addClass('active');
		$('#top_nav .navbar-collapse ul').css('height',`calc(100vh - ${topNavHeight + 50}px)`);
		$Nav.css('position', 'absolute').addClass('bg-grad');
		$RecruitLogoElement.addClass('active');
		DisplayWhiteLogo();
	}
});

/**
* ページ位置がスクロールにより変更されたときの処理
* 
*/
$(window).on('load scroll',function () {
	var winTop = $(this).scrollTop();

	//トグルを開いている途中でない時
	if(!($('#top_nav .navbar-collapse').hasClass('collapsing'))){

		//スクロール位置がトップでない
		if (winTop > navTop) {
			SetNormalHeader(topNavHeight + 'px');
			DisplayColorLogo();
			$Nav.addClass('scroll').addClass('shadow').addClass('border-bottom');
		//スクロール位置がトップ
		} else if (winTop <= navTop){
			SetNormalHeader('');
			DisplayColorLogo();
			$Nav.removeClass('scroll').removeClass('shadow').removeClass('border-bottom');
		}
	}
});

